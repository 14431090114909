import PollSwipeCard from "./PollSwipeCard";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import 'ui/styles/Transitions.css'
import 'ui/styles/Common.css'
import 'ui/styles/Poll.css'
import cross from 'assets/images/cross.png';
import check from 'assets/images/check.png';
import {useTranslation} from "react-i18next";
import {findCxEnergyById, useUser} from "../context/UserContext";
import {useNavigate} from "react-router-dom";
import TinderCard from 'ui/components/TinderCard'
import {onboardingFeedbackVideos} from "../../util/assets_paths";
import {Sheet} from "react-modal-sheet";

export default function SwipeCard({swipeQuestions}) {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(16);
    const [isSwiping, setIsSwiping] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [previousSectionIndex, setPreviousSectionIndex] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [showFeedback, setShowFeedback] = useState(false);
    const [hasCorrectAnswer, setHasCorrectAnswer] = useState(true);
    const [swipeDir, setSwipeDir] = useState();
    const [completeForm, setCompleteForm] = useState(false);
    const [canGoBack, setCanGoBack] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const {t} = useTranslation('global');
    const {answers, setAnswers, form, formData, updateEnergyCount} = useUser();
    const navigate = useNavigate();
    const currentIndexRef = useRef(16);

    const childRefs = useMemo(() => {
        if (swipeQuestions) {
            return Array(swipeQuestions.length).fill().map(() => React.createRef());
        }
        return [];
    }, [swipeQuestions]);

    // Check for user data
    useEffect(() => {
        if (formData.first_name === "" || formData.last_name === "") {
            navigate('/register');
        }
    }, [formData.first_name, formData.last_name, navigate]);

    // Initialize component with swipeQuestions
    useEffect(() => {
        if (swipeQuestions && swipeQuestions.length > 0 && form?.form_sections?.length > 0) {
            setCurrentQuestionIndex(swipeQuestions.length - 1);
            setCurrentSection(form.form_sections[0]);
            setFeedbackMessage(swipeQuestions[swipeQuestions.length - 1].messages_data[0]);
            setIsLoading(false);
        }
    }, [swipeQuestions, form]);

    const getFeedbackMessage = useCallback((savedAnswers) => {
        if (!savedAnswers || !swipeQuestions || !swipeQuestions[currentQuestionIndex]) return;

        const currentQuestion = swipeQuestions[currentQuestionIndex];
        const savedAnswer = savedAnswers.find((answer) => parseInt(answer.question_id) === currentQuestion.id);

        // Check for correct answer indicator
        setHasCorrectAnswer(currentQuestion.messages_data?.some(message => message.is_correct === '1') &&
            currentQuestion.question_type !== "2" && currentQuestion.question_type !== "0");

        if (savedAnswer && currentQuestion.messages_data?.length > 0) {
            let messageIndex = 0;

            switch (currentQuestion.question_type) {
                case 4: // Yes/No
                    messageIndex = savedAnswer.value === "1" ? 0 : 1;
                    break;
                case 2: // Rate
                    messageIndex = Math.min(parseInt(savedAnswer.value) - 1, currentQuestion.messages_data.length - 1);
                    break;
                case 1: // Radio
                    messageIndex = currentQuestion.answers.findIndex((answer) => answer.id === parseInt(savedAnswer.answer_id));
                    break;
                default:
                    break;
            }

            setFeedbackMessage(currentQuestion.messages_data[messageIndex]);
        }
    }, [currentQuestionIndex, swipeQuestions]);


    const handleSwipe = useCallback((dir, value, answerId, comment) => {
        setCanGoBack(false)

        if (previousSectionIndex !== currentSectionIndex) {
            setPreviousSectionIndex(currentSectionIndex)
        }

        const question = swipeQuestions[currentIndexRef.current];

        // update answers -- check if
        const newAnswers = answers.map(answer =>
            answer.question_id === question.id
                ? {...answer, value: value, answer_id: answerId !== null ? answerId.toString() : answerId, comment: comment}
                : answer
        );
        setAnswers(newAnswers);

        // Check if it is radio and has energy, compute the saved max energy to user context
        if (question.question_type === 1 && question.answers.every((answer) => answer.concept_id)) {
            // Get the max energy of the answer, which will be computed as the related CxEnergy of the concept_id. This CxEnergy will be sumed to the user's total energy map
            const maxEnergy = question.answers.find((answer) => answer.id === parseInt(answerId)).concept_id;

            // Get corresponding CxEnergy where id of CxEnergy is same as maxEnergy
            const energy = findCxEnergyById(maxEnergy);
            updateEnergyCount(energy)
        }


        // set show feedback if question has feedback messages
        if (question.messages_data && question.messages_data.length > 0) {
            setShowFeedback(true)
            getFeedbackMessage(newAnswers);
        }

        // check if new index is in swipeQuestions, wait until user close feedback to save
        if (currentIndexRef.current <= 0 || currentIndexRef.current >= swipeQuestions.length) {
            setCompleteForm(true)
            return;
        }

        // Change current question to next
        const newIndex = currentIndexRef.current - 1;
        setCurrentQuestionIndex(newIndex);
        currentIndexRef.current = newIndex;

        // check if the new question is in the next section, so change currentSection
        const questionInSection = form.form_sections[currentSectionIndex].questions.find(q => q.id === swipeQuestions[newIndex].id)
        if (questionInSection === undefined) {
            setCurrentSectionIndex(currentSectionIndex + 1)
            setCurrentSection(form.form_sections[currentSectionIndex + 1])
        }
    }, [answers, swipeQuestions, navigate]);

    const swipeWithButton = useCallback((dir, value, answerId, comment) => {
        setIsSwiping(true);

        // Manejar el swipe
        handleSwipe(dir, value, answerId, comment);

        // Esperar un breve momento antes de restablecer el estado de swiping
        setTimeout(() => {
            setIsSwiping(false); // Restablece el estado de swiping después de la animación
        }, 500); // Ajusta el tiempo según la duración de tu animación
    }, [handleSwipe]);

    const goBack = () => {
        if (currentQuestionIndex + 1 === swipeQuestions.length - 1) {
            setCanGoBack(false)
        } else {
            setCanGoBack(true)
        }

        const newIndex = currentQuestionIndex + 1; // Ir a la pregunta anterior
        setCurrentQuestionIndex(newIndex);
        currentIndexRef.current = newIndex;

        // check if the new question is in the previous section, so change currentSection
        const questionInSection = form.form_sections[currentSectionIndex].questions.find(q => q.id === swipeQuestions[newIndex].id)
        if (questionInSection === undefined) {
            setCurrentSectionIndex(currentSectionIndex - 1)
            setCurrentSection(form.form_sections[currentSectionIndex - 1])
            setPreviousSectionIndex(currentSectionIndex - 1)
        }
    };

    const handleCloseFeedback = () => {
        if (completeForm) {
            navigate('/finish');
        }
        setShowFeedback(false)

    }

    const swipeYesNo = (dir) => {
        console.log(dir)
        if (swipeQuestions[currentIndexRef.current].question_type === 4) {
            // get element with class section-image__like and set to visible
            const rightImage = document.querySelector('.section-image__like')
            const leftImage = document.querySelector('.section-image__dislike')
            if (dir === 'yes') {
                rightImage.classList.add('visible')
                leftImage.classList.remove('visible')
            } else if (dir === 'no') {
                rightImage.classList.remove('visible')
                leftImage.classList.add('visible')
            } else {
                rightImage.classList.remove('visible')
                leftImage.classList.remove('visible')
            }
        }
    }

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (!swipeQuestions || !swipeQuestions.length) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen p-4">
                <p className="text-lg text-gray-700 text-center">
                    {t('no_questions_available')}
                </p>
            </div>
        );
    }

    return (
        swipeQuestions ?
            <div id='swipeContainer' className={`card ${isSwiping ? 'swiping' : ''}`}>
                {swipeQuestions && swipeQuestions.length > 0 && swipeQuestions[currentQuestionIndex] &&
                    <TinderCard
                        ref={childRefs[currentIndexRef]}
                        key={swipeQuestions[currentQuestionIndex].id}
                        className="swipe"
                        onSwipe={(dir) => handleSwipe(dir, dir === 'right' ? "1" : "0", null, null)}
                        preventSwipe={swipeQuestions[currentQuestionIndex].question_type === 4 ? ['up', 'down'] : ['up', 'down', 'left', 'right']}
                        onSwipeYesNo={swipeYesNo}
                    >
                        <div className="card flex flex-col">
                            <PollSwipeCard
                                question={swipeQuestions[currentQuestionIndex]}
                                totalQuestions={swipeQuestions.length}
                                currentQuestionIndex={currentQuestionIndex}
                                onClick={swipeWithButton}
                                section={currentSection}
                                currentSectionIndex={currentSectionIndex}
                                back={goBack}
                                dir={swipeDir}
                                canGoBack={canGoBack}
                            />
                        </div>
                    </TinderCard>
                }
                {feedbackMessage && <Sheet isOpen={showFeedback} onClose={handleCloseFeedback}>
                    <Sheet.Container className='!h-[auto] !bottom-0 !overflow-scroll !rounded-t-xl'>
                        <Sheet.Content className='py-4 flex flex-col'>
                            {/*Top icon of correct or incorrect*/}
                            {hasCorrectAnswer && <div className='h-[3rem]'>
                                <img src={feedbackMessage?.is_correct === '1' ? check : cross} alt='icon for the feedback message'
                                     className='h-full w-full object-contain'/>
                            </div>}

                            <div className='flex-grow'>
                                {/* feedback message */}
                                <h1 className='text-center pt-4 font-bold text-2xl'>{form.form_sections[previousSectionIndex].text}</h1>
                                <p className='px-4 py-2 text-[#ababab] text-center'>{feedbackMessage?.title}</p>

                                {/* video */}
                                <video className='py-6'
                                       src={onboardingFeedbackVideos[previousSectionIndex]}
                                       autoPlay={true} loop={true}
                                       muted={true}
                                       controls={false}
                                       playsInline
                                />


                                {/* close button */}
                            </div>

                            <div className='px-4 pb-5'>
                                <button onClick={handleCloseFeedback} onTouchStart={handleCloseFeedback}
                                        className='generic-black-btn'>{t('close')}</button>
                            </div>

                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop/>
                </Sheet>}
            </div> : <div>Loading...</div>)
}
