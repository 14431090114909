import { IoMdClose } from 'react-icons/io'
import Picker from 'react-mobile-picker'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {useUser} from "../context/UserContext";
import zaraLogo from 'assets/images/white-zara-logo.png'
import {useNavigate} from "react-router-dom";
import 'ui/styles/LanguageSelector.css'
import 'ui/styles/Transitions.css'

export default function LanguageSelector ({supportedLanguages}) {
    const { locale, setLocale } = useUser()
    const l = (locale.includes('zh')) ? locale : locale.substring(0, 2)
    const { t } = useTranslation('global')
    const [pickerValue, setPickerValue] = useState({ lang: l })
    const closeTimeoutRef = useRef(null)
    const navigate = useNavigate();
    const [exit, setExit] = useState(false)

    // useEffect(() => {
    //     function preventDefault (e) {
    //         setExit(true)
    //         setLocale(pickerValue['lang'])
    //         // timeout to allow the animation to finish before navigating
    //         setTimeout(() => {
    //             setExit(false)
    //         }, 500)
    //         e.preventDefault()
    //     }
    //
    //     const closeComponent = () => {
    //         // navigate back where the form will be reloaded with the new language
    //         setLocale(pickerValue['lang'])
    //         navigate(-1)
    //     }
    //
    //     const startCloseTimeout = () => {
    //         closeTimeoutRef.current = window.setTimeout(closeComponent, 3000)
    //     }
    //
    //     const clearCloseTimeout = () => {
    //         if (closeTimeoutRef.current) {
    //             window.clearTimeout(closeTimeoutRef.current)
    //         }
    //     }
    //
    //     const resetCloseTimeout = () => {
    //         clearCloseTimeout()
    //         startCloseTimeout()
    //     }
    //
    //     const onTouchStart = () => {
    //         clearCloseTimeout()
    //     }
    //
    //     const onTouchMove = () => {
    //         clearCloseTimeout()
    //     }
    //
    //     const onTouchEnd = () => {
    //         resetCloseTimeout()
    //     }
    //
    //     // start if there is no touch event
    //     startCloseTimeout()
    //
    //     document.body.addEventListener('touchstart', onTouchStart)
    //     document.body.addEventListener('touchmove', onTouchMove)
    //     document.body.addEventListener('touchend', onTouchEnd)
    //     document.body.addEventListener('mousedown', onTouchEnd)
    //     document.body.addEventListener('touchmove', preventDefault, { passive: false })
    //
    //     return () => {
    //         clearCloseTimeout()
    //         document.body.removeEventListener('touchstart', onTouchStart)
    //         document.body.removeEventListener('touchmove', onTouchMove)
    //         document.body.removeEventListener('touchend', onTouchEnd)
    //         document.body.removeEventListener('mousedown', onTouchEnd)
    //         document.body.removeEventListener('touchmove', preventDefault)
    //     }
    // }, [pickerValue])

    const onTextClicked = (selected) => {
        if (selected) {
            setExit(true)
            const selectedLang = pickerValue['lang'];
            setLocale(selectedLang)
            
            // Set RTL for Arabic language immediately
            if (selectedLang === 'ar') {
                document.documentElement.setAttribute('dir', 'rtl');
                document.documentElement.classList.add('rtl');
            } else {
                document.documentElement.setAttribute('dir', 'ltr');
                document.documentElement.classList.remove('rtl');
            }
            
            // timeout to allow the animation to finish before navigating
            setTimeout(() => {
                setExit(false)
            }, 500)
            navigate(-1)
        }
    }

    return (
        <div className={`w-full h-dvh bg-black flex flex-col items-center slide-in pt-8 relative ${exit ? 'slide-out' : ''}`}>
            <IoMdClose className="absolute top-5 right-5 text-white text-3xl cursor-pointer" onClick={() => onTextClicked(true)}/>
            <img src={zaraLogo} alt='zara logo'/>
            <h1 className="mt-8 tablet:mt-[4rem] language-title">{t('select_language')}</h1>
            <div className="mt-16 w-full small:mt-5 tablet:mt-[5rem] wheel-container">
                <Picker value={pickerValue} onChange={setPickerValue} wheelMode="natural" itemHeight="65" height="350">
                    <Picker.Column key="lang" name="lang">
                        {Object.keys(supportedLanguages).map(name => (
                            <Picker.Item key={name} value={name} selected={name === pickerValue['lang']}>
                                {({ selected }) => (
                                    <a className={`${selected ? '' : 'unselected'} wheel-text`} onClick={() => onTextClicked(selected)}>{supportedLanguages[name].toUpperCase()}</a>
                                )
                                }
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                </Picker>
            </div>
        </div>
    )
}
