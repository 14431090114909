import {useEffect, useState} from "react";
import 'ui/styles/Splash.css'
import 'ui/styles/Transitions.css'
import 'ui/styles/Welcome.css'
import LegalModal from "../components/LegalModal";
import {useTranslation} from 'react-i18next';
import zaraLogo from 'assets/images/white-zara-logo.png'
import matchAnimation from 'assets/images/match-animation.gif'
import {LanguageSelectorTab} from "../components/LanguageSelectorTab";
import {useUser} from "../context/UserContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormFetch} from "../hooks/useFormFetch";
import * as Constants from "util/constants";
import i18n from 'i18next';
import WelcomeVideo from "./WelcomeVideo";
import welcome from "../../assets/images/match_welcome.png";
import AccessibilityMenu from "../components/AccessibilityMenu";

interface PageProps {
    font: string,
    initLang: string,
}

export default function Splash({font, initLang}: PageProps) {
    const {t} = useTranslation('global');

    // Variable to open or close legal modal
    const [openModal, setOpenModal] = useState(false)

    // Get necessary contexts
    const {locale, setLocale, setForm, store} = useUser()
    const navigate = useNavigate()
    const location = useLocation()

    // State to show initial page or welcome screen
    const [showWelcome, setShowWelcome] = useState(initLang == locale)

    // Fetch data and save to user context lang and data
    const {data, refetch} = useFormFetch({locale: locale ?? initLang, domainUrl: Constants.DOMAIN_URL_PRO, store: store})

    useEffect(() => {
        if (data) {
            setForm(data)
            setLocale(locale ?? initLang)

            i18n.changeLanguage(locale ?? initLang);
        }
    }, [data]);

    // Navigate to language selector page when opening tab, end when pop happened, change user context language and refecth data
    const onTabClicked = () => {
        // navigate to language selector page
        navigate('/language')
    }

    // useEffect(() => {
    //     // refetch and change data
    //     refetch({locale: locale, domainUrl: Constants.DOMAIN_URL_PRO})
    // }, [location, locale])

    return (
        <div className='h-screen w-screen relative overflow-hidden'>
            <div className={`absolute inset-0 flex justify-center ${locale === 'ar' ? 'items-end' : 'items-start'}`}>
                <img
                    src={welcome}
                    alt="center animation"
                    className={`welcome-img z-[2000] ${showWelcome ? 'center-image-animation' : 'scale-animation'}`}
                />
            </div>
            {showWelcome
                ? <WelcomeVideo onStart={() => setShowWelcome(false)}/>
                : <>
                    <div className='background-container w-full h-full box-border flex flex-col px-10 py-12'>
                        <LanguageSelectorTab locale={locale ?? initLang} onClick={onTabClicked}/>

                        {/* white zara logo */}
                        <div className='h-[3rem] fade-in-page delayed-animation'>
                            <img src={zaraLogo} alt='zara logo' className='h-full w-full object-contain'/>
                        </div>

                        <div className='flex-grow pt-8 fade-in'>
                            <img src={matchAnimation} alt='match animation' className='h-[15rem]'/>
                        </div>

                        {/* Start button -- show loader if no data */}
                        <div className='w-full relative mb-12'>
                            {data && <div className='flex flex-col items-center fade-in-page delayed-animation'>
                                <button className='generic-black-btn' onClick={() => {
                                    setOpenModal(true)
                                }}>{t('start').toUpperCase()}
                                </button>
                            </div>
                            }
                        </div>

                    </div>
                    {openModal &&
                        <LegalModal font={font} isOpen={openModal} onClose={() => setOpenModal(false)} legalContent={data?.legal_text}/>}
                </>}
        </div>


    )
}
